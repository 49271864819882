import { BaseStore } from "./index"
// import { Modal, ExcelGenerator } from "components";

class UserStore extends BaseStore {
  constructor() {
    super("userStore", {
      currentUser: null,
    })
  }

  setCurrentUser = (currentUser) => {
    this.setState({
      currentUser,
    })
  }
}

export default new UserStore()
