const core = {
  props: {
    MuiBreadcrumbs: {
      expandText: "Show path",
    },
    MuiTablePagination: {
      backIconButtonText: "Previous page",
      labelRowsPerPage: "จำนวนแถวที่แสดง:",
      labelDisplayedRows: ({ from, to, count }: any) =>
        `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`,
      nextIconButtonText: "Next page",
    },
    MuiRating: {
      getLabelText: (value: any) => `${value} Star${value !== 1 ? "s" : ""}`,
      emptyLabelText: "Empty",
    },
    MuiAutocomplete: {
      clearText: "Clear",
      closeText: "Close",
      loadingText: "Loading…",
      noOptionsText: "No options",
      openText: "Open",
    },
    MuiAlert: {
      closeText: "Close",
    },
    MuiPagination: {
      "aria-label": "Pagination navigation",
      getItemAriaLabel: (type: any, page: any, selected: any) => {
        if (type === "page") {
          return `${selected ? "" : "Go to "}page ${page}`;
        }
        if (type === "first") {
          return "Go to first page";
        }
        if (type === "last") {
          return "Go to last page";
        }
        if (type === "next") {
          return "Go to next page";
        }
        if (type === "previous") {
          return "Go to previous page";
        }
        return undefined;
      },
    },
  },
};

const filter = {
  noRowsLabel: "No rows",
  errorOverlayDefaultLabel: "No results found.",
  toolbarDensity: "Density",
  toolbarDensityLabel: "Density",
  toolbarDensityCompact: "Compact",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Comfortable",
  toolbarColumns: "Columns",
  toolbarColumnsLabel: "Select columns",
  toolbarFilters: "Filters",
  toolbarFiltersLabel: "Show filters",
  toolbarFiltersTooltipHide: "Hide filters",
  toolbarFiltersTooltipShow: "Show filters",
  toolbarFiltersTooltipActive: (count: any) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,
  columnsPanelTextFieldLabel: "Find column",
  columnsPanelTextFieldPlaceholder: "Column title",
  columnsPanelDragIconLabel: "Reorder column",
  columnsPanelShowAllButton: "Show all",
  columnsPanelHideAllButton: "Hide all",
  filterPanelAddFilter: "Add filter",
  filterPanelDeleteIconLabel: "Delete",
  filterPanelOperators: "Operator",
  filterPanelOperatorAnd: "And",
  filterPanelOperatorOr: "Or",
  filterPanelColumns: "Columns",
  filterPanelInputLabel: "Value",
  filterPanelInputPlaceholder: "Filter value",
  filterOperatorContains: "contains",
  filterOperatorEquals: "equals",
  filterOperatorStartsWith: "starts with",
  filterOperatorEndsWith: "ends with",
  filterOperatorIs: "is",
  filterOperatorNot: "is not",
  filterOperatorOnOrAfter: "is on or after",
  filterOperatorAfter: "is after",
  filterOperatorOnOrBefore: "is on or before",
  filterOperatorBefore: "is before",
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Show columns",
  columnMenuFilter: "Filter",
  columnMenuHideColumn: "Hide column",
  columnMenuUnsort: "Unsort",
  columnMenuSortAsc: "Sort by ASC",
  columnMenuSortDesc: "Sort by DESC",
  columnHeaderFiltersTooltipActive: (count: any) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,
  columnHeaderFiltersLabel: "Show filters",
  columnHeaderSortIconLabel: "Sort",
  footerRowSelected: (count: any) =>
    count !== 1
      ? `${count.toLocaleString()} rows selected`
      : `${count.toLocaleString()} row selected`,
  footerTotalRows: "Total Rows:",
};

const th = { core, filter };
export default th;
