import { SvgIconProps } from "@material-ui/core"
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined"
import Language from "@material-ui/icons/Language"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import PhoneInTalk from "@material-ui/icons/PhoneInTalk"
import MailOutline from "@material-ui/icons/MailOutline"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import SettingsOutlined from "@material-ui/icons/SettingsOutlined"
import InfoOutlined from "@material-ui/icons/InfoOutlined"
import Close from "@material-ui/icons/Close"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { t } from "i18next"
import React from "react"

const MuiIcon = {
  VisibilityOutlined,
  CloseOutlined,
  Check,
  ChevronLeft,
  DescriptionOutlined,
  Language,
  KeyboardArrowDown,
  PhoneInTalk,
  MailOutline,
  KeyboardArrowRight,
  SettingsOutlined,
  InfoOutlined,
  Close,
  ChevronRight,
  ExpandMore,
}

export type MuiIconCollections = keyof typeof MuiIcon

interface CustomIconProps extends SvgIconProps {
  name?: MuiIconCollections
}

export const MaterialIcon = (props: CustomIconProps) => {
  const { name, ...rest } = props

  if (MuiIcon[name as MuiIconCollections])
    return React.createElement(MuiIcon[name as MuiIconCollections], {
      ...rest,
    })
  else return <>{t("iconNotFound")}</>
}
