import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg"
import { ReactComponent as WarningIcon } from "assets/icon/warning_rounded_outlined.svg"
import { Button, Divider } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import Typography from "components/common/Typography"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import styled from "styled-components"
import Theme from "theme/custom"
import { formatDate, paths } from "utils/helper"
const EditingImage = Theme.icons.EditingImage
const ViewImage = Theme.icons.ViewImage
type InsuranceItemType = "warning" | "info"

const ItemConfig = {
  warning: {
    headerKey: ".warning.header",
    titleKey: ".warning.title",
    descriptionKey: ".warning.description",
    buttonTxtKey: ".warning.buttonTxt",
    backgroundColor: Theme.colors["Warning"],
  },
  info: {
    headerKey: ".info.header",
    titleKey: ".info.title",
    descriptionKey: ".info.description",
    buttonTxtKey: ".info.buttonTxt",
    backgroundColor: Theme.colors["Info"],
  },
}

const Container = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 4px 8px rgba(43, 43, 43, 0.1));
`

const Content = styled("div")`
  position: relative;
  background-color: ${Theme.colors["White / White"]};
  width: 300px;
  height: 540px;
  border-radius: 16px;
  border: 0.5px solid ${Theme.colors["Primary/Line"]};
`

const Header = styled("div")<{ type: InsuranceItemType }>`
  width: 100%;
  height: 36px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: ${(props) => ItemConfig[props.type].backgroundColor};
`

const Image = styled("div")`
  width: auto;
  height: 220px;
  margin: 16px 20px 40px 20px;
`

const Footer = styled("div")`
  position: absolute;
  width: 100%;
  height: 88px;
  bottom: 0;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .divider {
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 24px;
  }
`

export type InsuranceItemProps = {
  type: InsuranceItemType
  year: number
  date: Date | string
  id: string
  state: string
  t: TFunction
  handleClickButton: () => void
}

const InsuranceItemComponent = (props: InsuranceItemProps) => (
  <Container>
    <Content>
      <Header type={props.type}>
        <div style={{ display: "flex", alignItems: "center", padding: "8px", marginRight: 8 }}>
          {props.type === "info" ? (
            <InfoIcon width={20} height={20} style={{ marginRight: 8 }} fill={Theme.colors["White / White"]} />
          ) : (
            <WarningIcon width={20} height={20} style={{ marginRight: 8 }} fill={Theme.colors["Text/Primary Text"]} />
          )}

          <Typography
            color={props.type === "info" ? Theme.colors["White / White"] : Theme.colors["Text/Primary Text"]}
            variant="subtitle2"
          >
            {props.t(ItemConfig[props.type].headerKey, { date: formatDate(props.date, "dd/MM/yyyy,HH:mm") })}
          </Typography>
        </div>
      </Header>
      <div style={{ padding: "12px", textAlign: "center" }}>
        <Image>{props.type === "warning" ? <EditingImage /> : <ViewImage />}</Image>
        <Typography color={Theme.colors["Gray/Primary Text"]} variant="Header/16">
          {props.t(ItemConfig[props.type].titleKey, { year: props.year })}
        </Typography>
        <br />
        <Typography color={Theme.colors["Text/Secondary Text"]} variant="Body/14">
          {props.t(ItemConfig[props.type].descriptionKey, { year: props.year })}
        </Typography>
      </div>

      <Footer>
        <Divider className="divider" />
        <Button mr="12px" ml="12px" variant="contained" onClick={props.handleClickButton}>
          {props.t(ItemConfig[props.type].buttonTxtKey, { year: props.year })}
          <MaterialIcon fontSize="inherit" name="ChevronRight" />
        </Button>
      </Footer>
    </Content>
  </Container>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.home.InsuranceItem" }),
  withHooks((props: any, hooks: any) => {
    const { useCallback } = hooks
    const { id, type, year, date } = props

    const handleClickButton = useCallback(() => {
      if (type === "warning") paths.benefitManagementPath(id).push()
      else paths.viewBenefitPath(id).push()
    }, [id, type])

    return { type, year, date, handleClickButton }
  }),
)

export const InsuranceItem = enhancer(InsuranceItemComponent)
