import Drawer from "@material-ui/core/Drawer"
import { setRefreshToken, setToken } from "api"
import { ReactComponent as Logo } from "assets/image/logo.svg"
import { setCurrentLang } from "common/i18next"
import { Box, Button, Field, Form, IconButton, TextField, Typography } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import { compose, withFormik, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Theme from "theme/custom"
import { Yup, formatPhoneNumber, gql, notifySuccess, paths } from "utils/helper"

const AuthenButton = styled(Button)({
  background: Theme.colors["Green/Primary Text2"],
  borderRadius: "4px",
  color: Theme.colors["White / White"],
  height: "34px",
  fontFamily: "LINESeedSansTH",
  fontSize: "16px",
  fontWeight: 700,
  "&.MuiButton-contained.Mui-disabled": {
    color: "#fff",
    backgroundColor: Theme.colors["Primary/Disable"],
    border: "none",
  },
})

const ResendButton = styled(Button)({
  background: Theme.colors["White / White"],
  borderRadius: "4px",
  color: Theme.colors["Green/Primary Text2"],
  border: `1px solid ${Theme.colors["Green/Primary Text2"]}`,
  height: "34px",
  minWidth: "167px",
  fontFamily: "LINESeedSansTH",
  fontSize: "16px",
  fontWeight: 700,
  "&.MuiButton-contained.Mui-disabled": {
    color: "#fff",
    backgroundColor: Theme.colors["Primary/Disable"],
  },
})

const LanguageMenu = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 0px",
  background: Theme.colors["White / White"],
})

const CheckIcon = styled(MaterialIcon)({
  fontSize: "24px",
})

const Menu = styled(Box)({
  display: "flex",
  position: "absolute",
  top: "10px",
  right: "0",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  color: Theme.colors["White / White"],
})

type SignInPageProps = {
  signUp: () => void
  resetPassword: () => void
  refCode: string
  retryTime: number
  sendOTP: () => void
  verifyOTP: () => void
  t: TFunction
  disableSendOtpButton: boolean
  companyName: string
  companyPhoneNumber: string
  companyEmail: string
  errorEmail: boolean
  errorOtp: boolean
  email: string
  otp: string
  resendOtp: () => void
  showLanguageSetting: boolean
  openLanguageSetting: () => void
  handleCloseDrawer: () => void
  handleChangeLanguage: (lang: any) => void
  language: string
}

const SignInPage = (props: SignInPageProps) => (
  <>
    <Box style={{ background: Theme.colors["Green/Primary Text2"], height: "100vh", position: "relative" }}>
      <Box padding="64px 16px">
        <Menu>
          <Box display="flex" alignItems="center">
            <MaterialIcon name="Language" fontSize="inherit" style={{ marginRight: "5px" }} />
            <Typography variant="Subtitle/14">{props.t(".language")}</Typography>
          </Box>
          <Button color="secondary" variant="text" onClick={props.openLanguageSetting}>
            <MaterialIcon name="KeyboardArrowDown" fontSize="inherit" />
          </Button>
        </Menu>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Logo />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="Body/14" color="White / White" align="center" mt={4}>
            {props.t(".logoDescription")}
          </Typography>
        </Box>
      </Box>
      <Form>
        {!props.refCode ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                background: Theme.colors["White / White"],
                borderRadius: "16px 16px 0px 0px",
                minHeight: "calc(100vh - 292px)",
              }}
              padding="24px 16px"
            >
              <Box>
                <Typography variant="Header/32" color="Green/Primary Text2">
                  {props.t(".login")}
                </Typography>
                <br />
                <Typography variant="Body/14" color="Gray/Secondary Text" mt={2}>
                  {props.t(".title")}
                </Typography>
                <Typography
                  component="h3"
                  variant="h6"
                  color={props.errorEmail ? "Red/Error Text" : "Gray/Primary Text"}
                  mt={8}
                >
                  {props.t(".email")}
                </Typography>
                <Field
                  component={TextField}
                  name="email"
                  label=""
                  size="small"
                  fullWidth
                  mt={1}
                  placeholder={props.t(".emailPlaceholder")}
                />
              </Box>
              <Box mt={4}>
                <Typography variant="Body/14" color="Gray/Line" align="center">
                  {props.t(".aboutCompany", {
                    companyName: props.companyName,
                    companyPhoneNumber: formatPhoneNumber(props.companyPhoneNumber),
                    companyEmail: props.companyEmail,
                  })}
                </Typography>
                <AuthenButton fullWidth mt={4} onClick={props.sendOTP} disabled={props.disableSendOtpButton}>
                  {props.t(".requestOtp")}
                </AuthenButton>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                background: Theme.colors["White / White"],
                borderRadius: "16px 16px 0px 0px",
                minHeight: "calc(100vh - 292px)",
              }}
              padding="24px 16px"
            >
              <Box>
                <Typography variant="Header/32" color="Green/Primary Text2">
                  {props.t(".enterOtp")}
                </Typography>
                <br />
                <Typography variant="Body/14" color="Gray/Secondary Text" mt={2}>
                  {props.t(".enterTitle", { email: props.email })}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={8}>
                  <Typography variant="h6" color={props.errorOtp ? "Red/Error Text" : "Gray/Primary Text"}>
                    {props.t(".otp")}
                  </Typography>
                  <Typography variant="subtitle2" color="Gray/Secondary Text">
                    {props.t(".refCode", { refCode: props.refCode })}
                  </Typography>
                </Box>
                <Field
                  component={TextField}
                  name="otp"
                  label=""
                  fullWidth
                  mt={1}
                  placeholder={props.t(".otpPlaceholder")}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={8}>
                  <Typography variant="h5" color="Gray/Primary Text">
                    {props.t(".noOtp")}
                  </Typography>
                  <ResendButton onClick={props.resendOtp} disabled={props.retryTime > 0} ml={2}>
                    {props.t(".retryOtpAgain", {
                      retryTime: props.retryTime > 0 ? "(" + props.retryTime + ")" : "",
                    })}
                  </ResendButton>
                </Box>
              </Box>
              <Box mt={4}>
                <Typography variant="Body/14" color="Gray/Line" align="center">
                  {props.t(".aboutCompany", {
                    companyName: props.companyName,
                    companyPhoneNumber: formatPhoneNumber(props.companyPhoneNumber),
                    companyEmail: props.companyEmail,
                  })}
                </Typography>
                <AuthenButton fullWidth mt={4} onClick={props.verifyOTP} disabled={props.otp === ""}>
                  {props.t(".login")}
                </AuthenButton>
              </Box>
            </Box>
          </>
        )}
      </Form>
    </Box>
    <Drawer
      className="languageSetting"
      anchor="bottom"
      open={props.showLanguageSetting}
      onClose={props.handleCloseDrawer}
    >
      <Box display="flex" padding="16px 16px 24px 16px" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box height="24px" width="24px"></Box>
          <Typography variant="h3" color={Theme.colors["Gray/Primary Text"]}>
            {props.t(".languageOption")}
          </Typography>
          <IconButton style={{ padding: "0px" }} onClick={props.handleCloseDrawer}>
            <MaterialIcon
              name="CloseOutlined"
              style={{ fontSize: "24px" }}
              htmlColor={Theme.colors["Gray/Primary Text"]}
            />
          </IconButton>
        </Box>
        <LanguageMenu
          onClick={() => props.handleChangeLanguage("th")}
          mt="16px"
          style={{ borderBottom: `1px solid ${Theme.colors["Gray/Line"]}` }}
        >
          <Typography variant="h5" color={Theme.colors["Gray/Primary Text"]}>
            {props.t(".thai")}
          </Typography>
          {props.language === "th" && <CheckIcon name="Check" htmlColor={Theme.colors["Green/Primary Text2"]} />}
        </LanguageMenu>
        <LanguageMenu onClick={() => props.handleChangeLanguage("en")}>
          <Typography variant="h5" color={Theme.colors["Gray/Primary Text"]}>
            {props.t(".english")}
          </Typography>
          {props.language === "en" && <CheckIcon name="Check" htmlColor={Theme.colors["Green/Primary Text2"]} />}
        </LanguageMenu>
      </Box>
    </Drawer>
  </>
)

const API = {
  REQUEST_OTP: gql`
    mutation REQUEST_OTP($email: String!) {
      requestAppUserOtp(input: { email: $email }) {
        status
        token
        refCode
        otpRetryTimeInSec
        otpExpireInSec
      }
    }
  `,
  VERIFY_OTP: gql`
    mutation VERIFY_OTP($otp: String!, $refCode: String!, $email: String!, $token: String!) {
      verifyAppUserViaOtp(input: { otp: $otp, refCode: $refCode, email: $email, token: $token }) {
        currentUser {
          authenticationToken
          email
        }
        accessToken
        refreshToken
      }
    }
  `,
  GET_SETTING: gql`
    query GET_SETTING {
      getSetting {
        id
        companyNameTh
        companyNameEn
        companyEmail
        companyPhoneNumber
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
      otp: "",
      password: "",
      isRememberPassword: false,
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .email(".invalidEmailSyntax"),
    }),
  }),
  withTranslation({ prefix: "pages.auth.SignIn" }),
  withHooks((props: any, hooks: any) => {
    const { useMutation, useCallback, useEffect, useState, useMemo, useQuery } = hooks
    const { setFieldValue, t, signUp, resetPassword, values } = props

    const { i18n } = useTranslation()
    const { language, changeLanguage } = i18n

    const { loading, data, error } = useQuery(API.GET_SETTING, { fetchPolicy: "network-only" })

    const [requestOtp] = useMutation(API.REQUEST_OTP, {
      onCompleted: (data: any) => {
        setSeconds(data.requestAppUserOtp.otpRetryTimeInSec)
        setOtpToken(data.requestAppUserOtp.token)
        setRefCode(data.requestAppUserOtp.refCode)
        return data
      },
    })

    const [verifyOtp] = useMutation(API.VERIFY_OTP)
    const [seconds, setSeconds] = useState(0)
    const [otpToken, setOtpToken] = useState("")
    const [refCode, setRefCode] = useState("")
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorOtp, setErrorOtp] = useState(false)
    const [showLanguageSetting, setShowLanguageSetting] = useState(false)

    useEffect(() => {
      setSeconds(5)
    }, [])

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [seconds])

    const sendOTP = useCallback(async () => {
      try {
        const { email } = values
        await requestOtp({ variables: { email } })
      } catch (error: any) {
        setErrorEmail(true)
      }
    }, [requestOtp, values, setErrorEmail])

    const resendOtp = useCallback(async () => {
      try {
        const { email } = values
        setFieldValue("otp", "")
        await requestOtp({ variables: { email } })
        setSeconds(30)
      } catch (error: any) {
        setErrorEmail(true)
      }
    }, [requestOtp, values, setErrorEmail, setSeconds, setFieldValue])

    const verifyOTP = useCallback(async () => {
      const { email, otp } = values

      try {
        const { data } = await verifyOtp({ variables: { email, otp, refCode, token: otpToken } })
        const { accessToken, refreshToken } = data.verifyAppUserViaOtp
        setToken(accessToken)
        setRefreshToken(refreshToken)
        window.location.href = paths.homePath()
      } catch (error: any) {
        setErrorOtp(true)
      }
    }, [verifyOtp, values, otpToken, refCode])

    const disableSendOtpButton = useMemo(() => {
      if (values.email === "") {
        setErrorEmail(false)
        return true
      } else {
        setErrorEmail(false)
        return false
      }
    }, [values, setErrorEmail])

    const setting = useMemo(() => {
      if (loading) {
        return null
      }
      if (error) {
        return null
      }
      return data.getSetting
    }, [loading, data, error])

    useEffect(() => {
      setErrorOtp(false)
    }, [values?.otp])

    const email = values?.email
    const otp = values?.otp

    const openLanguageSetting = useCallback(() => {
      setShowLanguageSetting(true)
    }, [setShowLanguageSetting])

    const handleCloseDrawer = useCallback(() => {
      setShowLanguageSetting(false)
    }, [setShowLanguageSetting])

    const handleChangeLanguage = useCallback(
      (lang: any) => {
        changeLanguage(lang)
        setCurrentLang(lang)
        setShowLanguageSetting(false)
        notifySuccess(t(".dataSave"))
      },
      [changeLanguage, setShowLanguageSetting, t],
    )

    return {
      signUp,
      resetPassword,
      refCode: refCode,
      retryTime: seconds,
      sendOTP,
      verifyOTP,
      disableSendOtpButton,
      companyName: setting?.companyNameTh,
      companyPhoneNumber: setting?.companyPhoneNumber,
      companyEmail: setting?.companyEmail,
      errorEmail,
      errorOtp,
      email,
      otp,
      resendOtp,
      showLanguageSetting,
      openLanguageSetting,
      handleCloseDrawer,
      handleChangeLanguage,
      language,
    }
  }),
)

export default enhancer(SignInPage)
