// Theme
export const THEME_SET = "THEME_SET"
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  MOBILE: "MOBILE",
}

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST"
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS"
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE"
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT"
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST"
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS"
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE"
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST"
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS"
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE"
export const AUTH_SETUP_PASSWORD_REQUEST = "AUTH_SETUP_PASSWORD_REQUEST"
export const AUTH_SETUP_PASSWORD_SUCCESS = "AUTH_SETUP_PASSWORD_SUCCESS"
export const AUTH_SETUP_PASSWORD_FAILURE = "AUTH_SETUP_PASSWORD_FAILURE"

export const theme = {
  color: {
    dark: {
      primary: "#181818",
      secondaryText: "#393939",
      placeHolder: "#5a5a5a",
      border: "#9c9c9c",
      background: "#F5F5F5",
      sub: "#AFB1B6",
    },
    white: "#ffffff",
    blue: {
      primary: "#4dace9",
      hover: "#80c4ef",
      border: "#99d0f2",
      background: "#b3dbf6",
      backgroundLight: "#e6f3fc",
    },
  },
}
