import { ReactComponent as Clound } from "assets/icon/cloud upload.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Button, Divider, Grid, Modal } from "components"
import Typography from "components/common/Typography"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { useMemo } from "react"
import styled, { CSSProperties } from "styled-components"
import Theme from "theme/custom"
import { toCurrency } from "utils/helper"
import { ConsentModal } from "./Consent"
const Container = styled("div")`
  background-color: ${Theme.colors["Text/Placeholder"]};
  .content {
    padding: 16px 16px 24px 16px;
  }
  .detail-container {
    border: 1px solid ${Theme.colors["White / White"]};
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0px;
  }
`

interface FooterSummaryProps {
  t: TFunction
  availablePoint: string
  usingPoint: string
  totalPoint: string
  familyPoint: string
  showModal: () => void
  showModalCloud: () => void
  onSubmit: () => void
  isPreview: boolean
}

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}

const FooterSummaryComponent = ({
  t,
  availablePoint,
  usingPoint,
  totalPoint,
  showModal,
  isPreview,
  showModalCloud,
  familyPoint,
}: FooterSummaryProps) => (
  <Container>
    <div className="content">
      <Typography color={Theme.colors["White / White"]} variant="h6">
        {t(".summaryPoint")}
      </Typography>
      <div className="detail-container">
        <Grid container>
          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {t(".availablePoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ ...flexStyle, marginBottom: 16 }}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={{ ...flexStyle, marginBottom: 16 }}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {availablePoint}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {t(".usingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ ...flexStyle, marginBottom: 16 }}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={{ ...flexStyle, marginBottom: 16 }}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {usingPoint === "0" ? "0" : `-${usingPoint}`}
            </Typography>
          </Grid>

          {/* <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {t(".familyPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={flexStyle}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {familyPoint === "0" ? "0" : `-${familyPoint}`}
            </Typography>
          </Grid> */}

          <Divider style={{ width: "100%", marginBottom: 16 }} />

          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="h5">
              {t(".remainingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={flexStyle}>
            <Typography color={Theme.colors["White / White"]} variant="h5">
              {totalPoint}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {!isPreview && (
        <>
          {/* <Button style={{ border: "none" }} onClick={showModal}>
            {t(".confirm")}
          </Button> */}
          <div style={{ marginTop: "16px", font: "white", display: "flex", justifyContent: "center", gap: "4px" }}>
            <Button
              color={Theme.colors["White / White"]}
              style={{ border: "none", backgroundColor: "transparent", width: "100%" }}
              onClick={showModalCloud}
            >
              <Clound style={{ marginRight: "4px" }} />
              <Typography variant="Body/16">{t(".clound")}</Typography>
            </Button>
          </div>
        </>
      )}
    </div>
  </Container>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.FooterSummary" }),
  withHooks(
    (props: any, hooks: any): Omit<FooterSummaryProps, "t"> => {
      const { useMutation, useState } = hooks
      const {
        availablePoint,
        usingPoint,
        familyPoint = 0,
        onSubmit,
        isPreview,
        masterBenefitInsurancePlan,
        benefitInsuranceResTranslated,
        benefitInsurance,
        selectedBenefitInsurancePackage,
        id,
        t,
      } = props
      const [forceCompleteState, setForceCompleteState] = useState(false)

      const showModalCloud = () => {
        Modal.open({
          title: "",
          children: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              mb={4}
              mt={10}
            >
              <Clound style={{ width: "72px", height: "72px" }} fill={Theme.colors["Primary/Primary Text"]} />
              <Box mt={8}>
                <Typography variant="Header/16" color="Gray/Primary Text">
                  {t(".modalForceCompleteText1")}
                </Typography>
              </Box>
              <Box mt={4}>
                <Typography variant="Body/14" color="Text/Secondary Text">
                  {t(".modalForceCompleteText2")}
                </Typography>
              </Box>
              <Box mt={1} width="100%">
                <Typography variant="h5" color="Error/Error Text" style={{ whiteSpace: "normal" }}>
                  {t(".modalForceCompleteText3")}
                </Typography>
              </Box>
            </Box>
          ),
          onClose: Modal.close(),
          headerCloseButton: true,
          cancelButtonLabel: "ยกเลิก",
          cancelButtonVariant: "outlined",
          okButtonLabel: "ยืนยันแผนประกัน",
          okButtonVariant: "contained",
          onOk: () => {
            setForceCompleteState(true)
            showModal()
          },
        })
      }

      const showModal = () => {
        Modal.open({
          children: <ConsentModal onSubmit={() => onSubmit(true)} />,
          fullWidth: false,
          hideFooter: true,
        })
      }
      const calculatedPoint = useMemo(() => {
        return availablePoint - usingPoint - familyPoint
      }, [availablePoint, usingPoint, familyPoint])
      return {
        availablePoint: toCurrency(availablePoint, { minimumFractionDigits: 0 }),
        usingPoint: toCurrency(usingPoint, { minimumFractionDigits: 0 }),
        familyPoint: toCurrency(0, { minimumFractionDigits: 0 }),
        totalPoint: toCurrency(calculatedPoint, { minimumFractionDigits: 0 }),
        onSubmit,
        showModal,
        isPreview,
        showModalCloud,
      }
    },
  ),
)

export const FooterSummary = enhancer(FooterSummaryComponent)
