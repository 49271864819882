import { BoxProps } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { ReactComponent as EditIcon } from "assets/icon/edit.svg"
import MathJax from "better-react-mathjax/MathJax"
import MathJaxContext from "better-react-mathjax/MathJaxContext"
import { Box, Field, TextField, Typography } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import { compose, withHooks } from "enhancers"

interface LatexProps extends BoxProps {
  label?: string | boolean
  isEdit: boolean
  switchIsEdit: () => void
  value: string
  name: string
}

const LatexInput = (props: LatexProps) => (
  <Box {...props}>
    <Box display="flex" alignItems="center">
      {props.label && (
        <Typography variant="h4" mr={2}>
          {props.label}
        </Typography>
      )}
      {!props.isEdit && <EditIcon onClick={props.switchIsEdit} style={{ marginRight: "8px" }} />}
      {props.isEdit && (
        <Button
          onClick={props.switchIsEdit}
          color="primary"
          variant="text"
          style={{ marginRight: "8px", width: "16px" }}
        >
          <MaterialIcon name="VisibilityOutlined" fontSize="small" />
        </Button>
      )}
    </Box>
    <Box mt={2} width="100%">
      {!props.isEdit && props.value && (
        <Box style={{ border: "1px solid", borderRadius: "8px" }} p={2}>
          <MathJaxContext version={3}>
            <MathJax>{`${(props.value ?? "").replaceAll("\\\\", "\\")}`}</MathJax>
          </MathJaxContext>
        </Box>
      )}
      {props.isEdit && <Field name={props.name} component={TextField} width="100%" />}
    </Box>
  </Box>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState, useCallback } = hooks
    const { field, form, ...rest } = props
    const [isEdit, setIsEdit] = useState(false)

    const switchIsEdit = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    return {
      isEdit: isEdit,
      ...rest,
      switchIsEdit,
      value: field.value,
      name: field.name,
    }
  }),
)

export default enhancer(LatexInput)
