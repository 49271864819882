import ENV, { envConfig } from "../env.js"

export default async function () {
  const appVersion = envConfig.REACT_APP_VERSION || "unknown"
  console.log(`Version: ${appVersion}`)

  const url = new URL(ENV.API_ENDPOINT)
  const protocol = url.protocol ? `${url.protocol}//` : ""
  const port = url.port ? `:${url.port}` : ""

  const apiVersionUrl = `${protocol}${url.hostname}${port}/version`

  try {
    const response = await fetch(apiVersionUrl)
    const data = await response.text()
    console.log(`API Version: ${data}`)
  } catch (error) {
    console.error("Error fetching API version:", error)
  }
}
