import MuiTextField from "@material-ui/core/TextField"
import Hidden from "components/common/Hidden"
import { compose, defaultProps, withHooks } from "enhancers"
import { Autocomplete } from "formik-material-ui-lab"
import Fuse from "fuse.js"
import find from "lodash/find"
import get from "lodash/get"
import isNil from "lodash/isNil"
import map from "lodash/map"
import ListboxComponent from "./ListboxComponent"

import InputAdornment from "@material-ui/core/InputAdornment"

import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@material-ui/system"
import styled from "styled-components/macro"

const makeBoxProps = (Component) =>
  styled(Component)(borders, display, flexbox, palette, positions, shadows, sizing, spacing, typography)

const TextField = makeBoxProps(MuiTextField)

export const enhancer = compose(
  defaultProps({
    options: [],
    forcefix: true,
  }),
  withHooks((props, hooks) => {
    const { label, options, fuse, transformDisplay, ...restProps } = props
    const { useMemo, useCallback } = hooks

    const customOptions = useMemo(() => {
      return map(options, "value")
    }, [options])

    const freeSolo = props.freeSolo
    const customGetOptionLabel = useCallback(
      (value) => {
        const item = find(options, { value })
        const label = item?.label || ""

        if (!label && freeSolo) {
          return value
        } else {
          return label
        }
      },
      [options, freeSolo],
    )

    // set submitCount because formik doesn't touch field when submit. That's bad มากๆ see:https://github.com/jaredpalmer/formik/issues/445
    const touched = get(props.form.touched, props.field.name) || props.form.submitCount > 0
    const error = get(props.form.errors, props.field.name)
    const value = props.field.value
    const required = props.required
    const helperText = props.helperText
    const placeholder = props.placeholder
    const icon = find(options, { value })?.icon
    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={(touched && error) || helperText}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <Hidden when={!icon}>
                  <InputAdornment position="start">{icon}</InputAdornment>
                </Hidden>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      ),
      [label, touched, error, value, icon, required, helperText, placeholder],
    )

    const filterOptions = useCallback(
      (options, { inputValue }) => {
        if (inputValue || options.length > 200) {
          options = new Fuse(props.options, {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["value"],
            id: "label",
          }).search(inputValue ?? "", { limit: 5 })
          options = map(options, "item.value")
        }
        return options
      },
      [props.options],
    )

    const autoSelect = props.freeSolo && isNil(props.autoSelect) ? true : props.autoSelect

    return {
      key: props.forcefix ? value : undefined,
      ...restProps,
      options: [...customOptions],
      getOptionLabel: customGetOptionLabel,
      renderInput,
      ListboxComponent,
      filterOptions: fuse ? filterOptions : undefined,
      blurOnSelect: true,
      autoSelect,
    }
  }),
)

export default enhancer(Autocomplete)
