import FilterAltIcon from "assets/icon/filter_alt.svg"
import { Box, Button, IconButton } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import Typography from "components/common/Typography"
import { compose, withHooks, withStores } from "enhancers"
import { CSSProperties, PropsWithChildren, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Theme from "theme/custom"
import { ReactComponent as LogoIcon } from "../vendor/logo.svg"
import { PageFooter } from "./PageFooter"

const TitleContainer = styled("div")<Partial<TitleProps>>`
  position: relative;
  display: flex;
  align-items: flex-start;
  /* justify-content: ${(props) => (props.centered ? "center" : "flex-start")}; */
  background-color: ${(props) =>
    props.titleBg
      ? props.titleBg
      : props.titleDark
      ? Theme.colors["Gray/Background "]
      : Theme.colors["Green/Primary Text"]};
  padding: 0px 16px 0px 0px;
  height: 144px;
`

const ListIconContainer = styled("div")`
  position: absolute;
  top: 6px;
  right: 4px;
`

const CustomIconList = styled(MaterialIcon)`
  font-size: 32px;
`

const TitleText = styled.div<{ haveBackButton?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${(props) => (props.haveBackButton ? "16px 16px 16px 0px" : "16px 0px 16px 16px")};
`

const ListAmount = styled("div")`
  position: absolute;
  background-color: ${Theme.colors["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 10px;
  height: 12px;
  width: fit-content;
  padding: 0px 3.5px;
  z-index: 2;
`

const FilteredMark = styled("div")`
  position: absolute;
  background-color: ${Theme.colors["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 9px;
  right: 8px;
  height: 8px;
  padding: 0px 3.5px;
  z-index: 2;
`

interface TitleProps {
  title: string
  centered?: boolean
  personalRemain?: number
  onListClickTo?: string
  onBack?: () => void
  titleDark?: boolean
  titleBg?: string
  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean
}

const TitleEnhancer = compose(
  withStores((stores: any) => ({
    // currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    return { ...props }
  }),
)

const TitleComponent = (props: any) => {
  const {
    title,
    centered,
    onListClickTo,
    onBack,
    titleDark = false,
    showList,
    titleColor,
    titleBg,
    showFilter,
    openFilterModal,
    listAmount,
    hasFilter,
    backButtonColor,
    showLogo = false,
  } = props

  return (
    <>
      <TitleContainer centered={centered} titleDark={titleDark} titleBg={titleBg}>
        {showLogo && (
          <div style={{ padding: "16px 0px 16px 16px" }}>
            <LogoIcon />
          </div>
        )}
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          {onBack && (
            <IconButton
              onClick={onBack}
              style={{
                color: backButtonColor
                  ? backButtonColor
                  : titleDark
                  ? Theme.colors["Text/Primary Text"]
                  : Theme.colors["White / White"],
                padding: "16px",
                paddingRight: "10px",
              }}
            >
              <CustomIconList name="ChevronLeft" />
            </IconButton>
          )}
          <TitleText haveBackButton={onBack ? true : false}>
            <Typography
              variant="Header/20"
              color={titleColor ? titleColor : titleDark ? "Gray/Primary Text" : "White / White"}
              noWrap
            >
              {title}
            </Typography>
          </TitleText>
        </Box>

        {showList && onListClickTo && (
          <>
            {listAmount > 0 && (
              <Link to={onListClickTo}>
                <ListAmount>
                  <Typography
                    variant="caption"
                    color="White / White"
                    style={{ fontSize: "8px", fontWeight: 400, lineHeight: "16px" }}
                  >
                    {listAmount > 99 ? "99+" : listAmount}
                  </Typography>
                </ListAmount>
                <ListIconContainer>
                  <IconButton
                    style={{ color: titleDark ? Theme.colors["Text/Primary Text"] : Theme.colors["White / White"] }}
                  >
                    <CustomIconList name="DescriptionOutlined" fontSize="small" />
                  </IconButton>
                </ListIconContainer>
              </Link>
            )}
          </>
        )}
        {showFilter && (
          <ListIconContainer>
            {hasFilter && <FilteredMark />}
            <IconButton
              style={{ color: titleDark ? Theme.colors["Text/Primary Text"] : Theme.colors["White / White"] }}
              onClick={openFilterModal}
            >
              <img src={FilterAltIcon} style={{ width: "auto", height: "auto" }} alt="" />
            </IconButton>
          </ListIconContainer>
        )}
      </TitleContainer>
    </>
  )
}

const Title = TitleEnhancer(TitleComponent)

const FooterContainer = styled.div``

const FooterButton = styled(Button)`
  width: 50%;
`

interface FooterProps {
  onClick?: (props?: any) => void
  txt?: string
  to?: string
}

const Footer = (props: FooterProps) => {
  const { onClick, txt = "ถัดไป", to } = props

  const FooterButtonEle = () => <FooterButton onClick={onClick}>{txt}</FooterButton>
  return (
    <FooterContainer>
      {to ? (
        <Link to={to}>
          <FooterButtonEle />
        </Link>
      ) : (
        <FooterButtonEle />
      )}
    </FooterContainer>
  )
}

const CustomBox = styled(Box)<{ ["border-top"]?: string; bgcolor?: string; ["margin-top"]?: string }>`
  position: relative;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : Theme.colors["White / White"])};
  border-top: ${(props) => (props["border-top"] ? props["border-top"] : "0px")};
  margin-top: ${(props) => (props["margin-top"] ? `-${props["margin-top"]}` : "0px")};
  height: 100vh;
`

const ChildrenContainer = styled.div<{
  childrenMarginTop?: string
  childrenPadding?: string
  height?: string
  childrenBottomPadding?: string
}>`
  height: auto;
  position: relative;
  padding: ${(props) => (props.childrenPadding ? props.childrenPadding : "16px")};
  margin-top: ${(props) => (props.childrenMarginTop ? props.childrenMarginTop : "0px")};
  padding-bottom: ${(props) => (props.childrenBottomPadding ? props.childrenBottomPadding : "0px")};
`

interface PageContentProps extends PropsWithChildren<unknown> {
  title: string
  titleCentered?: boolean
  onFooterClick?: (props?: any) => void
  footerButtonTxt?: string
  to?: string
  onListClickTo?: string
  onBack?: () => void
  titleDark?: boolean
  bgColor?: string
  blueContainerHeight?: string
  childrenMarginTop?: string
  pageBorderTop?: string
  childrenPadding?: string
  titleColor?: string
  titleBg?: string
  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean
  backButtonColor?: string
  type?: "primary" | "secondary"
  showNavigation?: boolean
  showActionFooter?: boolean
  actionFooter?: React.ReactChild
  showLogo?: boolean
  bottomSpace?: string
  height?: boolean
}

const PageConfig = {
  primary: {
    titleColor: Theme.colors["White / White"],
    titleBackgroundColor: Theme.backgroundGradient,
  },
  secondary: {
    titleColor: Theme.colors["Gray/Primary Text"],
    titleBackgroundColor: Theme.colors["White / White"],
  },
}

export const PageContent = (props: PageContentProps) => {
  const {
    title,
    children,
    titleCentered = true,
    onListClickTo,
    onBack,
    titleDark = false,
    childrenMarginTop,
    childrenPadding,
    titleColor,
    titleBg,
    showFilter,
    openFilterModal,
    hasFilter,
    backButtonColor,
    type = "primary",
    showNavigation = false,
    showActionFooter = false,
    actionFooter,
    showLogo = false,
    bgColor,
    bottomSpace,
  } = props

  const [containerHeight, setContainerHeight] = useState(0)

  const headerStyle: CSSProperties =
    type === "primary"
      ? {
          backgroundColor: Theme.colors["Green/Primary Text"],
          height: "144px",
          position: "relative",
        }
      : {
          position: "relative",
          backgroundColor: Theme.colors["White / White"],
        }

  const child = document.querySelector(".child")
  const footer = document.querySelector(".footer")

  useEffect(() => {
    let childHeight = child?.clientHeight || 0
    let footerHeight = footer?.clientHeight || 0
    setContainerHeight(childHeight + footerHeight + 16)
  }, [child, footer, children])

  return (
    <CustomBox
      // minWidth={375}
      maxWidth={784}
      overflow="auto"
      mx="auto"
      bgcolor={bgColor}
    >
      <div>
        <Title
          onBack={onBack}
          onListClickTo={onListClickTo}
          title={title}
          centered={titleCentered}
          titleDark={titleDark}
          titleBg={titleBg || PageConfig[type].titleBackgroundColor}
          titleColor={titleColor || PageConfig[type].titleColor}
          showFilter={showFilter}
          openFilterModal={openFilterModal}
          hasFilter={hasFilter}
          backButtonColor={backButtonColor || PageConfig[type].titleColor}
          showLogo={showLogo}
        />
      </div>
      <ChildrenContainer
        height={`${containerHeight}px`}
        childrenMarginTop={childrenMarginTop}
        childrenPadding={childrenPadding}
        childrenBottomPadding={bottomSpace}
      >
        <div className="child">{children}</div>
      </ChildrenContainer>

      <div className="footer" style={{ position: "fixed", bottom: "0px", width: "100%" }}>
        {showActionFooter && actionFooter && (
          <Box minWidth={357} maxWidth={784} overflow="hidden" mx={0}>
            {actionFooter}
          </Box>
        )}

        {showNavigation && <PageFooter page="home"></PageFooter>}
      </div>
    </CustomBox>
  )
}
