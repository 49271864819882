import { pdfjs } from "react-pdf"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

/**
 * Represents a list of images with their corresponding URLs and titles.
 *
 * @typedef {Object} ImageInfo
 * @property {string} url - The URL of the image.
 * @property {string} title - The title of the image.
 */

/**
 * Renders a page of a PDF document and stores the rendered images in an array.
 *
 * @param {string} data - The PDF data.
 * @param {string} fileName - The name of the PDF file.
 * @return {ImageInfo[]} An array of image information objects.
 */
export const convertAndPreviewPdf = async (data, fileName) => {
  /**
   * Represents a list of images.
   *
   * @type {ImageInfo[]}
   */
  const imagesList = []
  const canvas = document.createElement("canvas")
  canvas.setAttribute("className", "canvas-temp")
  const pdf = await pdfjs.getDocument({ data }).promise
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i)
    const viewport = page.getViewport({ scale: 3 })
    canvas.height = viewport.height
    canvas.width = viewport.width
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    }
    await page.render(render_context).promise
    const imgUrl = canvas.toDataURL("image/jpg", 1.0)

    imagesList.push({
      url: imgUrl,
      title: `${fileName} - Page ${i} of ${pdf.numPages}`,
    })

    page.cleanup()
  }

  return imagesList
}

/**
 * Downloads a PDF from a given URL, converts it to base64, and then to a series of images.
 * Each image represents a page in the PDF. This function is designed to work with PDFs,
 * and the conversion is done through the `convertAndPreviewPdf` function.
 *
 * @async
 * @function urlUploader
 * @param {string} url - The URL of the PDF to download and convert.
 * @param {string} fileName - The name to use as a base for each page's title in the resulting images.
 * @returns {Promise<ImageInfo[]>} A promise that resolves to an array of `ImageInfo` objects,
 *                                  each representing an image converted from a page of the PDF.
 * @throws {Error} Throws an error if there is an issue with fetching the PDF,
 *                 reading the blob, or any internal errors during the conversion process.
 */
const urlUploader = async (url, fileName) => {
  try {
    // Fetch the URL
    const response = await fetch(url)
    const blob = await response.blob()

    // Convert the blob to a data URL
    const dataUrl = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })

    // Extract base64 data from data URL
    const base64Data = atob(dataUrl.replace(/.*base64,/, ""))

    // Call convertPdfToImages and return its result
    return convertAndPreviewPdf(base64Data, fileName)
  } catch (error) {
    console.error("Error in urlUploader:", error)
    throw error // Rethrow the error for caller to handle
  }
}

/**
 * Converts a PDF file to a series of images, where each image represents a page in the PDF.
 * This function first creates a URL for the provided PDF file and then uses the `urlUploader`
 * function to perform the conversion.
 *
 * @async
 * @function pdfToImages
 * @param {File} pdfFile - The PDF file to convert. It should be a File object typically obtained from an input element.
 * @returns {Promise<ImageInfo[]>} A promise that resolves to an array of `ImageInfo` objects,
 *                                  each representing an image converted from a page of the PDF.
 * @throws {Error} Throws an error if there are issues in processing the PDF file or during conversion.
 */
export const pdfToImages = async (pdfFile) => {
  const pdfUrl = URL.createObjectURL(pdfFile)
  return await urlUploader(pdfUrl, pdfFile.name)
}

/**
 * Converts a PDF file from a given URL to a series of images, where each image represents a page in the PDF.
 * This function uses the `urlUploader` function to perform the conversion.
 *
 * @async
 * @function pdfUrlToImages
 * @param {string} pdfUrl - The URL of the PDF file to convert.
 * @param {string} fileName - The name to use as a base for each page's title in the resulting images.
 * @returns {Promise<ImageInfo[]>} A promise that resolves to an array of `ImageInfo` objects,
 *                                 each representing an image converted from a page of the PDF.
 * @throws {Error} Throws an error if there are issues in fetching the PDF file or during conversion.
 */
export const pdfUrlToImages = async (pdfUrl, fileName) => {
  return await urlUploader(pdfUrl, fileName)
}
