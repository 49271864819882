import { BaseStore } from "stores";

class AuthStore extends BaseStore {
  constructor() {
    super("authStore", {
      currentUser: null,
    });
  }

  setCurrentUser = (currentUser) => {
    this.setState({
      currentUser,
    });
  };
}

export default new AuthStore();
