import "./wdyr"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import { Suspense } from "react"
import ReactDOM from "react-dom"
import "./mocks"

import { Provider } from "react-redux"

import { ApiProvider } from "api"

import DateFnsUtils from "@date-io/date-fns"
import { Helmet, HelmetProvider } from "react-helmet-async"

import { create } from "jss"
import { ThemeProvider } from "styled-components/macro"

import { ThemeProvider as MuiThemeProvider, StylesProvider, jssPreset } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import createTheme from "theme"

import { THEMES } from "./constants"

import { BrowserRouter, ExcelGenerator, ImageLightbox, Modal, Notification } from "components"
import store from "stores"

import Routes from "routes/Routes"

import { MaterialDesignContent, SnackbarProvider } from "notistack"

import { MaterialIcon } from "components/common/MaterialIcon"
import styled from "styled-components"

import i18n from "common/i18next"
import { I18nextProvider } from "react-i18next"
import * as locales from "common/mui"

import checkVersion from "./common/check-version"
import Theme from "theme/custom"
import cloneDeep from "lodash/cloneDeep"

import { CrossStorageClient } from "cross-storage"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import { withHooks } from "enhancers"
import { useMediaQuery } from "@material-ui/core"
import { backofficeEnv } from "env"
;(window as any).version = checkVersion

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: Theme.colors["Gray/Primary Text"],
    borderRadius: "16px",
  },
}))

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
})

// const theme = createTheme(THEMES.DEFAULT)

const storageClient = new CrossStorageClient(`${backofficeEnv.HOST}`, {})

storageClient
  .onConnect()
  .then(() => {
    return storageClient.get("CROSS_STORAGE_DATA")
  })
  .then((value: any) => {
    const benefitInsurance = JSON.parse(value)
    const newBenefitInsurance = cloneDeep(benefitInsurance)
    benefitInsuranceStore.initialPreviewBenefitInsurance(newBenefitInsurance)
  })
  .catch((error: any) => {
    console.log("Error retrieving value:", error)
  })

const customThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo, useTranslation } = hooks
  const isMobileSize = useMediaQuery("(max-width: 375px)")
  const { i18n } = useTranslation()

  const theme = useMemo(() => {
    let locale = {}
    switch (i18n.language) {
      case "en":
        locale = locales["en"].core
        break
      case "th":
        locale = locales["th"].core
        break
      default:
        throw new Error("The language you selected could not be found.")
    }

    return isMobileSize ? createTheme(THEMES.MOBILE, locale) : createTheme(THEMES.DEFAULT, locale)
  }, [isMobileSize, i18n.language])

  return { ...props, theme }
})

const customMuiThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo, useTranslation } = hooks
  const isMobileSize = useMediaQuery("(max-width: 375px)")
  const { i18n } = useTranslation()

  const theme = useMemo(() => {
    let locale = {}
    switch (i18n.language) {
      case "en":
        locale = locales["en"].core
        break
      case "th":
        locale = locales["th"].core
        break
      default:
        throw new Error("The language you selected could not be found.")
    }
    return isMobileSize ? createTheme(THEMES.MOBILE, locale) : createTheme(THEMES.DEFAULT, locale)
  }, [isMobileSize, i18n.language])

  return { ...props, theme }
})

const CustomMuiThemeProvider = customMuiThemeProviderEnhancer(MuiThemeProvider)
const CustomThemeProvider = customThemeProviderEnhancer(ThemeProvider)

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <Suspense fallback={true}>
        <I18nextProvider i18n={i18n}>
          <HelmetProvider>
            <Helmet titleTemplate="My Benefit" defaultTitle=" My Benefit" />
            <StylesProvider jss={jss}>
              <SnackbarProvider
                maxSnack={3}
                iconVariant={{
                  success: (
                    <MaterialIcon
                      name="Check"
                      style={{ marginRight: "10px" }}
                      htmlColor={Theme.colors["Green/Primary Text2"]}
                    />
                  ),
                }}
                Components={{
                  success: StyledMaterialDesignContent,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <CustomMuiThemeProvider>
                    <CustomThemeProvider>
                      {/* <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}> */}
                      <BrowserRouter>
                        <Routes />
                        <Modal storeName="appStore.mainModal" />
                        <Modal storeName="appStore.alertModal" />
                        <Modal storeName="appStore.confirmModal" />
                        <ImageLightbox storeName="appStore.imageLightbox" />
                        <Notification />
                        <ExcelGenerator storeName="appStore.excelGenerator" />
                      </BrowserRouter>
                      {/* </ThemeProvider>
                  </MuiThemeProvider> */}
                    </CustomThemeProvider>
                  </CustomMuiThemeProvider>
                </MuiPickersUtilsProvider>
              </SnackbarProvider>
            </StylesProvider>
          </HelmetProvider>
        </I18nextProvider>
      </Suspense>
    </ApiProvider>
  </Provider>,
  document.getElementById("root"),
)
