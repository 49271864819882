import { default as MuiAvatar } from "@material-ui/core/Avatar"
import { default as MuiBreadcrumbs } from "@material-ui/core/Breadcrumbs"
import { default as MuiChip } from "@material-ui/core/Chip"
import { default as MuiContainer } from "@material-ui/core/Container"
import { default as MuiDivider } from "@material-ui/core/Divider"
import { default as MuiFormControlLabel } from "@material-ui/core/FormControlLabel"
import { default as MuiGrid } from "@material-ui/core/Grid"
import { default as MuiIconButton } from "@material-ui/core/IconButton"
import { default as MuiMenu } from "@material-ui/core/Menu"
import { default as MuiPaper } from "@material-ui/core/Paper"
import { default as MuiRadio } from "@material-ui/core/Radio"
import { default as MuiCoreTextField } from "@material-ui/core/TextField"
import { default as MuiAlert } from "@material-ui/lab/Alert"
import { borders, display, flexbox, palette, positions, shadows, sizing, spacing } from "@material-ui/system"
import mapValues from "lodash/mapValues"
import styled from "styled-components/macro"

import CustomButton from "./Button"
import CustomCheckbox from "./Checkbox"
import CustomDatePicker from "./DatePicker"
import CustomErrorMessage from "./ErrorMessage"
import CustomLink from "./Link"
import CustomRadioGroup from "./RadioGroup"
import CustomSelect from "./Select"
import CustomSwitchInput from "./SwitchInput"
import CustomTextField from "./TextField"
import CustomTypography from "./Typography"

const makeBoxProps = (Component: any) =>
  styled(Component)(borders, display, flexbox, palette, positions, shadows, sizing, spacing)

CustomTextField.Email = makeBoxProps(CustomTextField.Email)
CustomTextField.PhoneNumber = makeBoxProps(CustomTextField.PhoneNumber)
CustomTextField.TaxId = makeBoxProps(CustomTextField.TaxId)

const CustomMuiTextField = makeBoxProps(MuiCoreTextField)

export const Email = CustomTextField.Email
export const PhoneNumber = CustomTextField.PhoneNumber
export const TaxId = CustomTextField.TaxId

const MuiComponents = mapValues(
  {
    Avatar: MuiAvatar,
    Paper: MuiPaper,
    Menu: MuiMenu,
    Breadcrumbs: MuiBreadcrumbs,
    Divider: MuiDivider,
    IconButton: MuiIconButton,
    Radio: MuiRadio,
    FormControlLabel: MuiFormControlLabel,
    Grid: MuiGrid,
    Chip: MuiChip,
    Container: MuiContainer,
    Alert: MuiAlert,
    Button: CustomButton,
    Link: CustomLink,
    Checkbox: CustomCheckbox,
    ErrorMessage: CustomErrorMessage,
    Select: CustomSelect,
    Typography: CustomTypography,
    RadioGroup: CustomRadioGroup,
    TextField: CustomTextField,
    SwitchInput: CustomSwitchInput,
    MuiTextField: CustomMuiTextField,
    DatePicker: CustomDatePicker,
  },
  makeBoxProps,
)

export const {
  Avatar,
  Paper,
  TextField,
  Typography,
  Alert,
  Button,
  Link,
  Checkbox,
  Menu,
  Breadcrumbs,
  Divider,
  ErrorMessage,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  Grid,
  Chip,
  SwitchInput,
  MuiTextField,
  Container,
} = MuiComponents

export { KeyboardDatePicker } from "formik-material-ui-pickers"

export { default as Accordion } from "@material-ui/core/Accordion"
export { default as AccordionDetails } from "@material-ui/core/AccordionDetails"
export { default as AccordionSummary } from "@material-ui/core/AccordionSummary"
export { default as Box } from "@material-ui/core/Box"
export { default as Tab } from "@material-ui/core/Tab"
export { default as Tabs } from "@material-ui/core/Tabs"
export { default as TabPanel } from "@material-ui/lab/TabPanel"

export { default as ClickAwayListener } from "@material-ui/core/ClickAwayListener"

export { default as DeleteIcon } from "@material-ui/icons/Delete"

export { Helmet } from "react-helmet-async"
export { AnimatedSwitch } from "react-router-transition"

export { default as BrowseButton } from "./BrowseButton"
export { default as Hidden } from "./Hidden"

export { default as Field } from "./Field"
export { default as FieldArray } from "./FieldArray"
export { default as Form } from "./Form"

export { default as BrowserRouter } from "./BrowserRouter"
export { default as Redirect } from "./Redirect"
export { default as Route } from "./Route"
export { default as Switch } from "./Switch"

export { default as ExcelGenerator } from "./ExcelGenerator"
export { default as ImageLightbox } from "./ImageLightbox"
export { default as Modal } from "./Modal"
export { default as Notification } from "./Notification"

export { default as FontAwesomeIcon } from "./FontAwesomeIcon"
export { default as PopMenu } from "./PopMenu"
export { default as UploadAvatar } from "./UploadAvatar"

export { default as DatePicker } from "./DatePicker"
export { default as ProgressBar } from "./ProgressBar"

export { Carousel } from "./Carousel"
