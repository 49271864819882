import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Divider, Grid, Typography } from "components"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { CSSProperties } from "styled-components"
import Theme from "theme/custom"
import { toCurrency } from "utils/helper"

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}

interface ResultScoreProps {
  t: TFunction
  availablePoint: string
  usingPoint: string
  familyPoint: string
  totalPoint: string
  typeEdit: string
  checkTotalPoint: boolean
}
const ResultScoreComponent = (props: ResultScoreProps) => (
  <Box style={{ display: "flex", flexDirection: "column" }}>
    <Box
      style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "32px" }}
    >
      <AlertSignIcon />
      <Typography className="title" variant="Header/16">
        {props.checkTotalPoint ? props.t(".editData") : props.t(".notRemainingPoint")}
      </Typography>
    </Box>
    {props.checkTotalPoint && (
      <Box style={{ display: "flex", flexDirection: "column", marginTop: "16px" }}>
        <Typography className="title" variant="Body/14">
          {props.t(".editDataList")}
        </Typography>
        <ul style={{ margin: 0, paddingLeft: "24px" }}>
          <li style={{ fontSize: "0.7em" }}>
            <Typography variant="Body/14">{props.t(props.typeEdit)}</Typography>
          </li>
        </ul>
      </Box>
    )}
    <Box style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
      {props.checkTotalPoint && <Typography variant="Header/16">{props.t(".totalPoint")}</Typography>}
      <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box style={{ border: " 1px solid #7C7C7C", borderRadius: "8px", padding: "16px" }}>
          <Grid container>
            <Grid item xs={7}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.t(".availablePoint")}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{ ...flexStyle, marginBottom: 16 }}>
              <PointCoinIcon width={16} height={16} />
            </Grid>
            <Grid item xs={4} sm={3} style={{ ...flexStyle, marginBottom: 16 }}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.availablePoint}
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.t(".usingPoint")}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={{ ...flexStyle, marginBottom: 16 }}>
              <PointCoinIcon width={16} height={16} />
            </Grid>
            <Grid item xs={4} sm={3} style={{ ...flexStyle, marginBottom: 16 }}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.usingPoint === "0" ? "0" : `-${props.usingPoint}`}
              </Typography>
            </Grid>

            {/* <Grid item xs={7}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.t(".familyPoint")}
              </Typography>
            </Grid>
            <Grid item xs={2} style={flexStyle}>
              <PointCoinIcon width={16} height={16} />
            </Grid>
            <Grid item xs={3} style={flexStyle}>
              <Typography color={Theme.colors["Text/Primary Text"]} variant="subtitle2">
                {props.familyPoint === "0" ? "0" : `-${props.familyPoint}`}
              </Typography>
            </Grid> */}

            <Divider style={{ width: "100%", marginBottom: 16 }} />

            <Grid item xs={7}>
              <Typography
                color={props.checkTotalPoint ? Theme.colors["Text/Primary Text"] : Theme.colors["Error/Error Text"]}
                variant="h5"
              >
                {props.t(".remainingPoint")}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={2} style={flexStyle}>
              <PointCoinIcon width={16} height={16} />
            </Grid>
            <Grid item xs={4} sm={3} style={flexStyle}>
              <Typography
                color={props.checkTotalPoint ? Theme.colors["Text/Primary Text"] : Theme.colors["Error/Error Text"]}
                variant="h5"
              >
                {props.totalPoint}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {props.checkTotalPoint && (
            <Typography variant="subtitle1" color={Theme.colors["Warning Text"]} style={{ whiteSpace: "normal" }}>
              {"*ถ้ายกเลิกการแก้ไขข้อมูลใหม่จะไม่ถูกบันทึกและกลับไปเป็นชุดข้อมูลเดิม"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  </Box>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<ResultScoreProps, "t"> => {
      const { useMemo } = hooks
      const { t, typeEdit, availablePoint, usingPoint, familyPoint, totalPoint } = props
      const checkTotalPoint = useMemo(() => {
        return totalPoint >= 0 ? true : false
      }, [totalPoint])
      return {
        availablePoint: toCurrency(availablePoint, { minimumFractionDigits: 0 }),
        usingPoint: toCurrency(usingPoint, { minimumFractionDigits: 0 }),
        familyPoint: toCurrency(familyPoint, { minimumFractionDigits: 0 }),
        totalPoint: toCurrency(totalPoint, { minimumFractionDigits: 0 }),
        typeEdit,
        checkTotalPoint,
      }
    },
  ),
)

export const ResultScore = enhancer(ResultScoreComponent)
