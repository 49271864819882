import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import { MaterialIcon } from "components/common/MaterialIcon"
import { Typography, Box, Link, IconButton, Button } from "components"
import styled from "styled-components"
import Theme from "theme/custom"
import appStore from "stores/appStore"
import { ReactComponent as AvatarIcon } from "assets/icon/avatar-icon.svg"
import { ReactComponent as LogoutIcon } from "assets/icon/logout-icon.svg"
import { ReactComponent as LogoutModalIcon } from "assets/icon/logout_modal-icon.svg"
import { useTranslation } from "react-i18next"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

const Menu = styled("div")`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  cursor: pointer;
  border-bottom: 1px solid ${Theme.colors["Gray/Line"]};
`

const MenuContainer = styled("div")`
  padding: 0px 16px;
`

const MenuIcon = styled(MaterialIcon)`
  font-size: 16px;
  margin-right: 8px;
`

const AccountContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 32px 16px;
  background: ${Theme.colors["Green/Background"]};
`

const LogoutMenu = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  cursor: pointer;
  bottom: 76px;
  left: 0;
  right: 0;
  position: fixed;
`

const AccountComponent = (props: any) => (
  <>
    <PageContent
      type="secondary"
      title={props.t(".title")}
      titleCentered={false}
      childrenPadding="0px"
      titleBg={Theme.colors["White / White"]}
      titleColor={Theme.colors["Green/Primary Text2"]}
      showNavigation
    >
      <Link to="/profile">
        <AccountContainer>
          <AvatarIcon />
          <Box ml="24px">
            <Box display="flex" flexDirection="row">
              <Box mr="8px">
                <Typography variant="Header/24" color={Theme.colors["Gray/Primary Text"]}>
                  {props.firstName}
                </Typography>
              </Box>
              <Typography variant="Header/24" color={Theme.colors["Gray/Primary Text"]}>
                {props.lastName}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle2" color={Theme.colors["Gray/Secondary Text"]}>
                {props.t(".viewProfile")}
              </Typography>
              <MaterialIcon
                name="KeyboardArrowRight"
                style={{ fontSize: "16px", marginLeft: "4px", color: Theme.colors["Gray/Secondary Text"] }}
              />
            </Box>
          </Box>
        </AccountContainer>
      </Link>
      <MenuContainer>
        <Link to="/setting">
          <Menu>
            <MenuIcon name="SettingsOutlined" htmlColor={Theme.colors["Gray/Primary Text"]} />
            <Typography variant="h5" color={Theme.colors["Gray/Primary Text"]}>
              {props.t(".setting")}
            </Typography>
          </Menu>
        </Link>
        <Link to="/about-belive">
          <Menu>
            <MenuIcon name="InfoOutlined" htmlColor={Theme.colors["Gray/Primary Text"]} />
            <Typography variant="h5" color={Theme.colors["Gray/Primary Text"]}>
              {props.t(".aboutBelive")}
            </Typography>
          </Menu>
        </Link>
      </MenuContainer>
      <LogoutMenu onClick={props.handleOpenModal}>
        <Typography variant="h3" color={Theme.colors["Green/Primary Text2"]}>
          {props.t(".signOut")}
        </Typography>
        <LogoutIcon style={{ marginLeft: "4px" }} />
      </LogoutMenu>
    </PageContent>
    <Dialog open={props.isShow} className="signOutModal">
      <DialogTitle></DialogTitle>
      <DialogContent
        style={{
          objectFit: "contain",
          padding: "8px 16px 24px 16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="flex-end" mb="32px">
            <IconButton style={{ padding: "8px" }} onClick={props.handleCloseModal}>
              <MaterialIcon
                name="CloseOutlined"
                style={{ fontSize: "18px" }}
                htmlColor={Theme.colors["Green/Primary Text2"]}
              />
            </IconButton>
          </Box>
          <Box mb="32px" display="flex" justifyContent="center">
            <LogoutModalIcon />
          </Box>
          <Box mb="16px" display="flex" justifyContent="center">
            <Typography variant="Subtitle/16" color={Theme.colors["Gray/Primary Text"]}>
              {props.t(".signOut")}
            </Typography>
          </Box>
          <Box mb="32px" display="flex" justifyContent="center">
            <Typography variant="Body/14" color={Theme.colors["Gray/Secondary Text"]}>
              {props.t(".logoutDescrip")}
            </Typography>
          </Box>
          <Box display="flex">
            <Button
              width="100%"
              height="34px"
              mr="16px"
              onClick={props.handleCloseModal}
              style={{
                color: Theme.colors["Green/Primary Text2"],
                border: `1px solid ${Theme.colors["Green/Primary Text2"]}`,
                borderRadius: "4px",
                fontFamily: "LINESeedSansTH",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {props.t(".cancel")}
            </Button>
            <Button
              width="100%"
              height="34px"
              onClick={props.handleSignOut}
              style={{
                color: Theme.colors["White / White"],
                background: Theme.colors["Green/Primary Text2"],
                borderRadius: "4px",
                fontFamily: "LINESeedSansTH",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {props.t(".confirm")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  </>
)

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.userStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.account.index" }),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useState } = hooks
    const { currentUser } = props

    const { i18n } = useTranslation()
    const { language } = i18n

    const [isShow, setIsShow] = useState(false)

    const handleCloseModal = useCallback(async () => {
      setIsShow(false)
    }, [setIsShow])

    const handleOpenModal = useCallback(async () => {
      setIsShow(true)
    }, [setIsShow])

    const handleSignOut = useCallback(async () => {
      appStore.logout()
      setIsShow(false)
    }, [setIsShow])

    return {
      firstName: language === "th" ? currentUser?.employee?.firstNameTh : currentUser?.employee?.firstNameEn,
      lastName: language === "th" ? currentUser?.employee?.lastNameTh : currentUser?.employee?.lastNameEn,
      handleSignOut,
      isShow,
      handleCloseModal,
      handleOpenModal,
    }
  }),
)

export const AccountPage = enhancer(AccountComponent)
