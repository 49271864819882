import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { Box, Button, Modal } from "components"
import T from "components/common/T"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { compose, withFormik, withHooks, withStores, withTranslation } from "enhancers"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import { TFunction } from "i18next"
import isEmpty from "lodash/isEmpty"
import { useLocation } from "react-router-dom"
import paths from "routes/paths"

import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import Theme from "theme/custom"
import { convertBase64ToFile, forceDownload } from "utils/helper"
import { BenefitDetailComponent } from "../detail"

export interface BenefitDetailComponentProps {
  t: TFunction
  year: number | string
  name: string
  age: number
  insuranceTypes: any[]
  handleClickBack: () => void
  handleClickEdit: (typeId: string) => void
  availablePoint: number
  usingPoint: number
  isPreview: boolean
  download: () => void
  selectedBenefitInsurancePlans: any
  type: EnumMasterBenefitInsuranceType
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    insuranceType: stores.benefitInsuranceStore.insuranceType,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsurance: stores.benefitInsuranceStore.benefitInsurance,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color={Theme.colors["Primary/Primary Text"]} noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const { useCallback, useParams, useMemo, useEffect } = hooks
      const {
        selectedBenefitInsurancePlans,
        initialValues,
        setValues,
        disablePreventLeaveDirtyForm,
        insuranceType: getInsuranceType,
        benefitInsurance,
      } = props
      const { id } = useParams()

      const selectedBenefitInsurancePlansTranslated = selectedBenefitInsurancePlans

      const insuranceTypes = useMemo(() => {
        if (selectedBenefitInsurancePlansTranslated && getInsuranceType) {
          const returnItems: any[] = []

          for (const insuranceType of getInsuranceType) {
            const existed = returnItems.find((type: any) => type.id === insuranceType.id)

            if (!existed) {
              returnItems.push({
                id: insuranceType.id,
                title: insuranceType.name,
                selected: {
                  name: selectedBenefitInsurancePlansTranslated[insuranceType.id]
                    ? selectedBenefitInsurancePlansTranslated[insuranceType.id].masterInsurancePlan.name
                    : "",
                  point: selectedBenefitInsurancePlansTranslated[insuranceType.id]?.point || 0,
                },
              })
            }
          }
          return returnItems
        }
        return []
      }, [selectedBenefitInsurancePlansTranslated, getInsuranceType])

      const download = useCallback(async () => {
        if (
          typeof benefitInsurance.fileBase64 === "undefined" &&
          typeof benefitInsurance.fileName === "undefined" &&
          typeof benefitInsurance.fileObject === "undefined"
        ) {
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {"client.pages.main.benefit.detail.errorDownloadFile"}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {"client.pages.main.benefit.detail.upload"}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button style={{ border: "none" }}>
                <T>{`client.pages.main.benefit.detail.ok`}</T>
              </Button>
            ),
          })
        } else {
          if (!benefitInsurance.fileBase64) {
            const file = benefitInsurance.fileObject
            if (file) {
              let blob = await fetch(file.url)
                .then((r) => r.blob())
                .then((blobFile) => new File([blobFile], file.fileName, { type: blobFile.type }))
              const url = URL.createObjectURL(blob)
              forceDownload(url, file.fileName)
            }
          } else {
            const data = convertBase64ToFile(benefitInsurance.fileBase64, benefitInsurance.fileName)
            if (data) {
              const url = URL.createObjectURL(data)
              forceDownload(url, benefitInsurance.fileName)
            }
          }
        }
      }, [benefitInsurance])
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }

      const handleClickBack = useCallback(() => {
        paths.previewBenefitPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .previewBenefitCustomEditPath(id, {
              insuranceTypeId: typeId,
              masterInsurancePlanId: selectedBenefitInsurancePlans[typeId].id,
            })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePlans],
      )

      const usingPoint = useMemo(
        () =>
          insuranceTypes.reduce((accumulator: any, insuranceType: any) => {
            return accumulator + insuranceType.selected.point
          }, 0),
        [insuranceTypes],
      )

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePlans) setValues(selectedBenefitInsurancePlans)
      }, [selectedBenefitInsurancePlans, setValues, initialValues])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        download,
        year: benefitInsurance?.year,
        age: benefitInsurance?.age,
        name: `${benefitInsurance?.firstName || ""} ${benefitInsurance?.lastName || ""}`,
        insuranceTypes,
        handleClickBack,
        handleClickEdit,
        availablePoint: benefitInsurance?.maxPoint || 0,
        usingPoint,
        isPreview,
        selectedBenefitInsurancePlans,
        type: EnumMasterBenefitInsuranceType.custom,
      }
    },
  ),
)

export const PreviewBenefitDetailPage = enhancer(BenefitDetailComponent)
