import { gql, useLazyQuery } from "@apollo/client"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { Box, Button, Modal } from "components"
import T from "components/common/T"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { compose, withFormik, withHooks, withStores, withTranslation } from "enhancers"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"
import { useHistory, useLocation } from "react-router-dom"
import paths from "routes/paths"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import previousBenefitInsuranceStore from "stores/previousBenefitInsuranceStore"
import Theme from "theme/custom"
import { calculateAge, forceDownload, notifySuccess } from "utils/helper"
import { ConsentModal } from "./Consent"
import { BenefitDetailComponent, BenefitDetailComponentProps } from "./detail"
import { ResultScore } from "./ResultScore"

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        attendeeId
        type
        attendee {
          points
          attendeeGroup {
            point
          }
          employee {
            prefixNameTh
            prefixNameEn
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            birthDate
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
              packagePricingType
              insurancePackagesInsurancePlans {
                id
                insurance {
                  id
                  nameTh
                  nameEn
                  premium
                  remarkTh
                  remarkEn
                  description
                }
              }
            }
          }
        }
        year
        points
        hasFile
      }
    }
  `,
  CHANGE_BENEFIT_INSURANCE_PACKAGE: gql`
    mutation CHANGE_BENEFIT_INSURANCE_PACKAGE(
      $benefitInsuranceId: String!
      $masterInsurancePackages: JSON!
      $points: Float!
      $id: String!
      $forceCompleteState: Boolean
      $selectedBenefitInsurancePackage: JSON
    ) {
      changeBenefitInsurancePackage(
        input: {
          benefitInsuranceId: $benefitInsuranceId
          masterInsurancePackages: $masterInsurancePackages
          points: $points
          id: $id
          isForceComplete: $forceCompleteState
          selectedBenefitInsurancePackage: $selectedBenefitInsurancePackage
        }
      ) {
        id
        benefitInsurance {
          attendee {
            state
          }
        }
      }
    }
  `,
  GET_FILE_DOWNLOAD: gql`
    query GET_FILE_DOWNLOAD($id: String!) {
      downloadFile(id: $id) {
        file {
          url
          fileName
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.userStore.currentUser,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    selectedPreviousBenefitInsuranceStore: stores.previousBenefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color={Theme.colors["Primary/Primary Text"]} noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const {
        t,
        currentUser,
        selectedBenefitInsurancePackage,
        selectedPreviousBenefitInsuranceStore,
        initialValues,
        setInitialValues,
        setValues,
        values,
        disablePreventLeaveDirtyForm,
      } = props
      const { useCallback, useParams, useEffect, useQuery, useMemo, useDataTranslation, useMutation } = hooks
      const history = useHistory()
      const { id } = useParams()
      const [loadGreeting, { data: dataFile }] = useLazyQuery(API.GET_FILE_DOWNLOAD, {
        variables: { id },
        onCompleted: async (data: any) => {
          if (dataFile) {
            let blob = await fetch(dataFile.downloadFile.file.url)
              .then((r) => r.blob())
              .then((blobFile) => new File([blobFile], dataFile.downloadFile.file.fileName, { type: blobFile.type }))
            const url = URL.createObjectURL(blob)
            forceDownload(url, dataFile.downloadFile.file.fileName)
          }
        },
        onError: (error: any) => {
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {"client.pages.main.benefit.detail.errorDownloadFile"}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {"client.pages.main.benefit.detail.upload"}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button style={{ border: "none" }}>
                <T>{`client.pages.main.benefit.detail.ok`}</T>
              </Button>
            ),
          })
        },
        fetchPolicy: "network-only",
      })
      const download = useCallback(async () => {
        await loadGreeting()
      }, [loadGreeting])
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }
      const { loading, data, error } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        onCompleted: (data: any) => {
          const benefitInsurancePackages = data.benefitInsurance.benefitInsurancePackages[0]
          if (isEmpty(selectedBenefitInsurancePackage)) {
            benefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: benefitInsurancePackages.insurancePackage.masterInsurancePackage.id,
              masterInsurancePackage: benefitInsurancePackages.insurancePackage.masterInsurancePackage,
              point: benefitInsurancePackages.requiredPoint,
            })
            previousBenefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: benefitInsurancePackages.insurancePackage.masterInsurancePackage.id,
              masterInsurancePackage: benefitInsurancePackages.insurancePackage.masterInsurancePackage,
              point: benefitInsurancePackages.requiredPoint,
            })
          }
        },
        fetchPolicy: "network-only",
      })

      const [changeBenefitInsurancePackage] = useMutation(API.CHANGE_BENEFIT_INSURANCE_PACKAGE)
      const selectedBenefitInsurancePackageTranslated = useDataTranslation(selectedBenefitInsurancePackage)

      const benefitInsurance = useMemo(() => {
        if (data) {
          return data?.benefitInsurance
        }
        return {}
      }, [data])

      const benefitInsurancePackages = useMemo(() => {
        if (selectedBenefitInsurancePackageTranslated) {
          return [
            {
              id: selectedBenefitInsurancePackageTranslated?.id,
              title: "Package ประกัน",
              selected: {
                name: selectedBenefitInsurancePackageTranslated?.masterInsurancePackage?.name,
                point: selectedBenefitInsurancePackageTranslated.point,
              },
            },
          ]
        }
        return []
      }, [selectedBenefitInsurancePackageTranslated])

      const age = calculateAge(currentUser?.employee?.birthDate)

      const handleClickBack = useCallback(() => {
        paths.benefitManagementPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .benefitPackageManagementEditPath(id, {
              masterInsurancePackageId: selectedBenefitInsurancePackage.id,
              benefitInsuranceId: benefitInsurance?.id,
            })
            .push()
        },
        [disablePreventLeaveDirtyForm, id, selectedBenefitInsurancePackage, benefitInsurance],
      )

      const onSubmit = useCallback(
        async (forceCompleteState: boolean) => {
          const params = {
            benefitInsuranceId: benefitInsurance.id,
            masterInsurancePackages: selectedBenefitInsurancePackage.masterInsurancePackage,
            points: selectedBenefitInsurancePackage.point,
            selectedBenefitInsurancePackage,
          }
          try {
            disablePreventLeaveDirtyForm()
            await changeBenefitInsurancePackage({
              variables: {
                ...params,
                id,
                forceCompleteState,
              },
            })
            Modal.close()
            notifySuccess(t(".dataSave"))

            if (forceCompleteState) paths.viewBenefitPath(id).push()
            // else paths.benefitManagementPath(id).push()
          } catch (e) {
            console.log("ERROR : ", e)
          }
        },
        [
          changeBenefitInsurancePackage,
          selectedBenefitInsurancePackage,
          benefitInsurance,
          id,
          disablePreventLeaveDirtyForm,
          t,
        ],
      )
      const availablePoint = useMemo(() => {
        if (data) {
          return data?.benefitInsurance?.attendee.points
        }
      }, [data])

      // useEffect(() => {
      //   if (isEmpty(initialValues) && data) {
      //     const benefitInsurancePackages = data?.benefitInsurance?.benefitInsurancePackages[0]
      //     setInitialValues({
      //       id: benefitInsurancePackages.insurancePackage.masterInsurancePackage.id,
      //       masterInsurancePackage: benefitInsurancePackages.insurancePackage.masterInsurancePackage,
      //       point: benefitInsurancePackages.requiredPoint,
      //     })
      //   }
      // }, [initialValues, data, setInitialValues])

      // useEffect(() => {
      //   if (!isEmpty(initialValues) && selectedBenefitInsurancePackage) {
      //     setValues(selectedBenefitInsurancePackage)
      //   }
      // }, [setValues, selectedBenefitInsurancePackage, initialValues])

      const calculatedPoint = useMemo(() => {
        return availablePoint - benefitInsurancePackages[0]?.selected?.point - 0
      }, [availablePoint, benefitInsurancePackages])

      const openModal = useCallback(() => {
        Modal.open({
          children: (
            <ResultScore
              typeEdit={".ownType"}
              availablePoint={availablePoint}
              usingPoint={benefitInsurancePackages[0]?.selected?.point}
              familyPoint={0}
              totalPoint={calculatedPoint}
            />
          ),
          okButtonVariant: "contained",
          okButtonLabel: `${calculatedPoint >= 0 ? "บันทึกข้อมูล" : "เลือกใหม่อีกครั้ง"}`,
          cancelButtonVariant: "outlined",
          cancelButtonLabel: "ยกเลิกการแก้ไข",
          disableBackdropClick: true,
          onOk: () => {
            showModal()
            previousBenefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedBenefitInsurancePackage)
          },
          onCancel: () => {
            benefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedPreviousBenefitInsuranceStore)
            Modal.close()
          },
        })
      }, [
        availablePoint,
        benefitInsurancePackages,
        calculatedPoint,
        selectedBenefitInsurancePackage,
        selectedPreviousBenefitInsuranceStore,
      ])

      const showModal = useCallback(() => {
        Modal.open({
          children: <ConsentModal onSubmit={() => onSubmit()} onCancel={() => openModal()} />,
          fullWidth: false,
          hideFooter: true,
        })
      }, [onSubmit, openModal])

      useEffect(() => {
        if (
          selectedPreviousBenefitInsuranceStore &&
          selectedBenefitInsurancePackage &&
          !isEqual(selectedPreviousBenefitInsuranceStore, selectedBenefitInsurancePackage)
        ) {
          openModal()
          // Modal.open({
          //   children: (
          //     <ResultScore
          //       typeEdit={".ownType"}
          //       availablePoint={availablePoint}
          //       usingPoint={benefitInsurancePackages[0]?.selected?.point}
          //       familyPoint={0}
          //       totalPoint={calculatedPoint}
          //     />
          //   ),
          //   okButtonVariant: "contained",
          //   okButtonLabel: `${calculatedPoint >= 0 ? "บันทึกข้อมูล" : "เลือกใหม่อีกครั้ง"}`,
          //   cancelButtonVariant: "outlined",
          //   cancelButtonLabel: "ยกเลิกการแก้ไข",
          //   disableBackdropClick: true,
          //   onOk: () => {
          //     showModal()
          //     previousBenefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedBenefitInsurancePackage)
          //   },
          //   onCancel: () => {
          //     benefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedPreviousBenefitInsuranceStore)
          //     Modal.close()
          //   },
          // })
        }
      }, [openModal, selectedBenefitInsurancePackage, selectedPreviousBenefitInsuranceStore])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        download,
        year: benefitInsurance?.year,
        age,
        name: currentUser?.employee?.firstNameTh + " " + currentUser?.employee?.lastNameTh,
        insuranceTypes: benefitInsurancePackages,
        handleClickBack,
        handleClickEdit,
        availablePoint,
        usingPoint: benefitInsurancePackages[0]?.selected?.point,
        onSubmit,
        isPreview,
        benefitInsurance,
        selectedBenefitInsurancePackage,
        id,
        type: EnumMasterBenefitInsuranceType.package,
        hasFile: benefitInsurance?.hasFile || false,
      }
    },
  ),
)

export const BenefitDetailPackagePage = enhancer(BenefitDetailComponent)
