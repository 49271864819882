import { ReactComponent as AccountIcon } from "assets/icon/account-icon.svg"
import { ReactComponent as AccountGreenIcon } from "assets/icon/account_green-icon.svg"
import { ReactComponent as HomeIcon } from "assets/icon/home-icon.svg"
import { ReactComponent as HomeGreenIcon } from "assets/icon/home_green-icon.svg"
import { Box, Grid, Typography } from "components"
import { compose, withHooks, withTranslation } from "enhancers"
import { PropsWithChildren, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import Theme from "theme/custom"

const Footer = styled(Box)`
  border-top: 1px solid ${Theme.colors["Gray/Line"]};
  text-align: "center";
  background: ${Theme.colors["White / White"]};
  width: auto;
`

interface PageFooterProps extends PropsWithChildren<unknown> {
  currentPath: "home" | "account"
  t: Function
}
const PageFooterComponent = (props: PageFooterProps) => {
  const { currentPath, t } = props

  return (
    <Footer minWidth={357} maxWidth={784} overflow="hidden">
      <Grid container>
        <Grid item xs={6}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={currentPath === "home" ? "4px 0px 4px 0px" : "8px 0px 4px 0px"}
              borderTop={currentPath === "home" ? "4px solid #2EB375" : ""}
            >
              <Box mt="4px">{currentPath === "home" ? <HomeGreenIcon /> : <HomeIcon />}</Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color={currentPath === "home" ? "Green/Primary Text2" : "Gray/Placeholder2"}
                >
                  {t(".home")}
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to="/account" style={{ textDecoration: "none" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={currentPath === "account" ? "4px 0px 4px 0px" : "8px 0px 4px 0px"}
              borderTop={currentPath === "account" ? "4px solid #2EB375" : ""}
            >
              <Box mt="4px">{currentPath === "account" ? <AccountGreenIcon /> : <AccountIcon />}</Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color={currentPath === "account" ? "Green/Primary Text2" : "Gray/Placeholder2"}
                >
                  {t(".account")}
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Footer>
  )
}

const enhancer = compose(
  withTranslation({ prefix: "components.common.Footer" }),
  withHooks((props: any, hooks: any) => {
    const { pathname } = useLocation()
    const currentPath = useMemo(() => {
      return pathname.split("/")[1]
    }, [pathname])
    return { currentPath }
  }),
)
export const PageFooter = enhancer(PageFooterComponent)
