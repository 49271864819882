import React from "react"
import { compose, withHooks } from "enhancers"
import { Document, Page, pdfjs } from "react-pdf"
// import "react-pdf/dist/esm/Page/AnnotationLayer.css"
// import "react-pdf/dist/esm/Page/TextLayer.css"
import Divider from "@material-ui/core/Divider"
import { ImageLightbox } from "components/common"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

/**
 * @typedef {import('./UploadDocumentCard.tsx').ImageInfo} ImageInfo
 */

/**
 * Renders a page of a PDF document and stores the rendered images in an array.
 *
 * @param {string} data - The PDF data.
 * @param {string} fileName - The name of the PDF file.
 * @return {void} This function does not return a value.
 */
const convertAndPreviewPdf = async (data, fileName) => {
  /**
   * Represents a list of images.
   *
   * @type {ImageInfo[]}
   */
  const imagesList = []
  const canvas = document.createElement("canvas")
  canvas.setAttribute("className", "canvas-temp")
  const pdf = await pdfjs.getDocument({ data }).promise
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i)
    const viewport = page.getViewport({ scale: 1 })
    // const viewport = page.getViewport({ scale: 1.5 })
    canvas.height = viewport.height
    canvas.width = viewport.width
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    }
    await page.render(render_context).promise
    // const imgUrl = canvas.toDataURL("image/png")
    const imgUrl = canvas.toDataURL("image/jpg", 0.5)

    imagesList.push({
      url: imgUrl,
      title: `${fileName} - Page ${i} of ${pdf.numPages}`,
    })

    page.cleanup()
  }

  // @ts-ignore
  ImageLightbox.open({
    images: imagesList,
  })
}

/**
 * Downloads an image from a given URL and renders it on the page.
 *
 * @param {string} url - The URL of the image to be downloaded.
 * @param {string} fileName - The name of the image file.
 * @return {void} This function does not return a value.
 */
const UrlUploader = (url, fileName) => {
  fetch(url).then((response) => {
    response.blob().then((blob) => {
      let reader = new FileReader()
      reader.onload = (e) => {
        const data = atob(e.target.result.replace(/.*base64,/, ""))
        convertAndPreviewPdf(data, fileName)
      }
      reader.readAsDataURL(blob)
    })
  })
}

/**
 * Generates a preview URL for a given PDF file.
 *
 * @param {File} pdfFile - The PDF file to generate a preview URL for.
 * @param {ImageInfo[]} pdfImages - An array of `ImageInfo` objects representing the images in the PDF.
 * @return {void} This function does not return a value.
 */
export const previewPdf = (pdfFile, pdfImages = []) => {
  if (pdfImages.length > 0) {
    // @ts-ignore
    ImageLightbox.open({
      images: pdfImages,
    })
  } else {
    const pdfUrl = URL.createObjectURL(pdfFile)
    UrlUploader(pdfUrl, pdfFile.name)
  }
}

/**
 * Generates a preview URL for a given PDF file.
 *
 * @param {ImageInfo[]} pdfImages - An array of `ImageInfo` objects representing the images in the PDF.
 * @return {void} This function does not return a value.
 */
export const previewPdfImages = (pdfImages = []) => {
  if (pdfImages.length > 0) {
    // @ts-ignore
    ImageLightbox.open({
      images: pdfImages,
    })
  } else {
    console.error("No images to preview.")
  }
}

const PdfViewerComponent = ({ pageNumber, numPages, file, onDocumentLoad }) => {
  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoad}>
        {Array.from(new Array(numPages), (el, index) => (
          <>
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              // width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
            />
            <Divider />
          </>
        ))}
      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  )
}

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useCallback } = hooks
    const { file } = props

    const [pageNumber, setPageNumber] = useState(null)
    const [numPages, setNumPages] = useState(1)

    const onDocumentLoad = ({ numPages }) => {
      setNumPages(numPages)
      setPageNumber(1)
    }

    const changePage = useCallback(
      (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset)
      },
      [setPageNumber],
    )

    const previousPage = useCallback(() => {
      changePage(-1)
    }, [changePage])

    const nextPage = useCallback(() => {
      changePage(1)
    }, [changePage])

    return {
      file,
      onDocumentLoad,
      previousPage,
      nextPage,
      pageNumber,
      numPages,
    }
  }),
)
const PdfViewer = enhancer(PdfViewerComponent)
export default PdfViewer
